import React, { useState } from 'react'
import './statut.scss'
import { graphql, Link } from 'gatsby'
import { GatsbyImage, StaticImage } from 'gatsby-plugin-image'

import SEO from '../components/seo'
import { Parallax } from 'react-scroll-parallax'

import MainFrameCover from '../components/MainFrameCover'
import Intro from '../animations/Intro'
import { useInView } from 'react-intersection-observer'

import Slider from 'react-slick'
import ACFWYSIWYG from '../components/Content/ACF/WYSIWYG'

import Footer from '../components/Footer'

// import Modal from '../components/Modal'


function Page(props) {
  const { data, pageContext } = props

  const [refHero, refHeroInView] = useInView({ triggerOnce: true })

  return (
    <>

      <MainFrameCover />

      <main id="mainframe" className={`active main-${pageContext.slug === "/" ? "frontpage" : pageContext.slug}`}>

        <SEO title={"Instytut Współpracy Międzynarodowej"} description={"Instytut Współpracy Międzynarodowej Bezpieczny Wschód"} />
        
        <section ref={refHero} className="section-hero statut">
          <Intro visible={refHeroInView} in={{ appear: 0, bg: 1000 }} delayIn={0} mounted={true} stay={true} className="c0 t padd-top">
          <Intro visible={refHeroInView} in={{ fade: 800 }} delayIn={100} mounted={true} stay={true} className="">      

            <div className='content padd-2'>

            <h3 className='text-center'>STATUT FUNDACJI<br />INSTYTUT WSPÓŁPRACY MIĘDZYNARODOWEJ BEZPIECZNY WSCHÓD</h3>
            <h3 className='text-center padd-bottom-half'>POSTANOWIENIA OGÓLNE</h3>

              <div className='divider padd-top-half text-center fs-125'>
                <p>&sect; 1 </p>
              </div>
            
              <ol>
                <li>Fundacja pod nazwą Instytut Współpracy Międzynarodowej Bezpieczny Wschód (dawniej: Fundacja Współpracy Polsko &ndash; Ukraińskiej OLIMP) zwana dalej w treści Statutu &bdquo;Fundacją&rdquo;, została ustanowiona dnia 22.04.2015 roku przez Olega Semeniuka, zwanego dalej Fundatorem, aktem notarialnym sporządzonym przed Amelię Pachucką - Kutrzeba, zastępcę Grażyny Lewickiej w Lublinie, za repertorium A nr 1566/2015 na podstawie powszechnie obowiązujących przepisów prawa oraz niniejszego statutu.</li>
                <li>Fundacja jest apolityczna i niezwiązana z żadnym wyznaniem.</li>
              </ol>

              <div className='divider padd-top-half text-center fs-125'>
                <p> &sect; 2</p>
              </div>

              <p>Fundacja ma osobowość prawną.</p>
              
              <div className='divider padd-top-half text-center fs-125'>
                <p> &sect; 3</p>
              </div>
              <p>Siedzibą Fundacji jest miejscowość Warszawa, gmina m.st. Warszawa, województwo mazowieckie.</p>
              
              <div className='divider padd-top-half text-center fs-125'>
                <p> &sect; 4</p>
              </div>
              <ol>
              <li>Terenem działalności Fundacji jest obszar Rzeczpospolitej Polskiej, przy czym w zakresie niezbędnym dla właściwego realizowania celów może ona prowadzić działalność także poza granicami Rzeczpospolitej Polskiej.</li>
              <li>Fundacja może dla celów współpracy z zagranicą posługiwać się tłumaczeniem nazwy <br />w wybranych językach.</li>
              <li>Fundacja może tworzyć oddziały, filie i przedstawicielstwa w kraju i za granicą, a także przystępować do organizacji i fundacji w kraju i za granicą.</li>
              <li>Realizacja zadań statutowych Fundacji może być prowadzona we współpracy z innymi podmiotami.</li>
              </ol>
              
              <div className='divider padd-top-half text-center fs-125'>
                <p> &sect; 5</p>
              </div>
              <ol>
              <li>Fundacja może używać oznak i pieczęci z danymi identyfikacyjnymi Fundacji.</li>
              <li>Fundacja może ustanawiać certyfikaty, odznaki honorowe i medale honorowe oraz przyznawać je wraz z innymi nagrodami i wyróżnieniami osobom fizycznym, osobom prawnym oraz jednostkom organizacyjnym nieposiadającym osobowości prawnej, zasłużonym dla Fundacji i przyczyniającym się do realizacji celów Fundacji.</li>
              <li>Fundacja może prowadzić działalność gospodarczą w rozmiarach służących realizacji jej celów.</li>
              </ol>
              
              <div className='divider padd-top-half text-center fs-125'>
                <p> &sect; 6</p>
              </div>

              <h4 className='text-center'>CELE I ZASADY DZIAŁANIA FUNDACJI</h4>
              <p>Celami Fundacji są:</p>
              <ol>
              <li>Promowanie pokojowego dialogu pomiędzy narodami Polski oraz krajami Europy Centralnej i Wschodniej;</li>
              <li>Inicjowanie działań kształtujących postawy dobrego sąsiedztwa, przyjaźni, szacunku <br />i zaufania na zasadach równości pomiędzy społeczeństwami Polski i krajów Europy;</li>
              <li>Prowadzenie rzetelnego dialogu historycznego zmierzające do zbliżenia mieszkańców Polski i Ukrainy, pokojowego i przyjaznego kształtowania nowej historii obu Narodów oraz do wyjaśnienia trudnej historii obu Narodów;</li>
              <li>Rzetelne informowanie społeczeństwa polskiego o działaniach zbrojnych prowadzonych przez państwa Europejskie objęte działaniami zbrojnymi i wojennymi;</li>
              <li>Pomoc ofiarom cywilnym, żołnierzom, ich rodzinom oraz osobom poszkodowanym <br />w konfliktach zbrojnych;</li>
              <li>Wspieranie Polaków mieszkających poza granicami Polski oraz mniejszości narodowych zamieszkującej Polskę;</li>
              <li>Wspieranie współpracy gospodarczej i handlowej pomiędzy podmiotami gospodarczymi krajów Europy Centralnej i Wschodniej;</li>
              <li>Inicjowanie międzynarodowej współpracy kulturalnej, naukowej, sportowej;</li>
              <li>Aktywizacja zawodowa obywateli RP z zakresu bezpieczeństwa i ochrony;</li>
              <li>Aktywizacja zawodowa weteranów RP oraz weteranów i z krajów Europy Centralnej <br />i krajów wschodnich objętych konfliktami zbrojnymi obecnie i w przeszłości;</li>
              <li>Działania służące upowszechnieniu wiedzy militarno-obronnej w społeczeństwie poprzez edukację o zagrożeniach oraz sposobach przeciwdziałania im;</li>
              <li>Udzielanie pomocy osobom fizycznym w tworzeniu nowych przedsiębiorstw oraz udzielanie pomocy wszelkim podmiotom prawa w realizacji przedsięwzięć gospodarczych, poprzez prowadzenie inkubatora przedsiębiorczości na zasadach określonych <br />w regulaminie inkubatorów i w obowiązujących przepisach;</li>
              </ol>
              <div className='divider padd-top-half text-center fs-125'>
                <p> &sect; 7</p>
              </div>
              <p>Fundacja realizuje swoje cele poprzez:</p>
              <ol>
              <li>Organizowanie spotkań, prezentacji, imprez kulturalnych i edukacyjnych, zbiórek, akcji promocyjnych zmierzających do realizacji celów Fundacji;</li>
              <li>Organizowanie pomocy materialnej, organizacyjnej, prawnej, edukacyjnej, medycznej <br />i finansowej;</li>
              <li>Współpracę w zakresie realizacji celów statutowych Fundacji z osobami fizycznymi, osobami prawnymi, jednostkami i ośrodkami specjalistycznymi, organizacjami, instytucjami oraz organami administracji rządowej i samorządowej;</li>
              </ol>
              <ol start="4">
              <li>Promocję i organizację wolontariatu;</li>
              <li>Międzynarodową kooperację na rzecz rozwoju demokracji, rynku, edukacji, nauki, kultury, wymiany informacji, ochrony środowiska i zdrowia, pomocy społecznej i humanitarnej.</li>
              </ol>
              
              <div className='divider padd-top-half text-center fs-125'>
                <p> &sect; 8</p>
              </div>
              <p>Dla osiągnięcia swoich celów Fundacja może wspierać działalność innych osób i instytucji zbieżną z jej celami.</p>
              
              <div className='divider padd-top-half text-center fs-125'>
                <p> &sect; 9</p>
              </div>
              <ol>
              <li>Majątek Fundacji stanowi fundusz założycielski w kwocie pieniężnej 2.000 zł (słownie: dwa tysiące złotych) oraz środki finansowe, prawa majątkowe, nieruchomości i ruchomości nabyte przez Fundację w trakcie jej działania.</li>
              <li>Z funduszu założycielskiego określonego w ust. 1 powyżej Fundator przeznacza kwotę nie większą niż 700 zł (słownie: siedemset złotych) na opłaty związane z rejestracją Fundacji</li>
              </ol>
              
              <div className='divider padd-top-half text-center fs-125'>
                <p> &sect; 10</p>
              </div>
              <p>Realizacja celów Fundacji finansowana jest z funduszu oraz z dochodów uzyskanych przez Fundację, na zasadach przewidzianych w niniejszym statucie.</p>
              <ol>
              <li>Dochody Fundacji mogą pochodzić z:
              <ol >
              <li type="a">darowizn, spadków, zapisów,</li>
              <li type="a">subwencji osób trzecich,</li>
              <li type="a">dochodów ze zbiórek i imprez publicznych,</li>
              <li type="a">dochodów z majątku ruchomego i nieruchomego,</li>
              <li type="a">działalności gospodarczej prowadzonej przez Fundację.</li>
              </ol>
              </li>
              </ol>
              <div className='divider padd-top-half text-center fs-125'>
                <p> &sect; 11</p>
              </div>
              <ol>
              <li>Dochody pochodzące z dotacji, subwencji, darowizn, spadków i zapisów mogą być użyte na realizację celów Fundacji tylko z poszanowaniem woli spadkobierców lub donatorów.</li>
              <li>W sprawach przyjęcia darowizn i dziedziczenia oświadczenia wymagane przepisami prawa składa Zarząd Fundacji.</li>
              <li>W przypadku powołania Fundacji do dziedziczenia, jej Zarząd składa oświadczenie o przyjęciu spadku z dobrodziejstwa inwentarza.</li>
              </ol>
              
              <div className='divider padd-top-half text-center fs-125'>
                <p> &sect; 12</p>
              </div>
              <h4 className='text-center'>WŁADZE FUNDACJI</h4>
              <ol>
              <li>Jedynym organem władzy Fundacji w momencie jej założenia jest Zarząd Fundacji, powołany przez Fundatora.</li>
              <li>Organami Fundacji jest Zarząd.</li>
              <li>Członkowie Zarządu Fundacji mogą otrzymywać wynagrodzenie.,</li>
              <li>Członkowie Zarządu Fundacji są uprawnieni do zawierania umów i podejmowania innych czynności, na podstawie których otrzymują wynagrodzenie, w tym umów o pracę. Ograniczeń przewidzianych w art. 108 kodeksu cywilnego nie stosuje się.</li>
              <li>Kadencja Członka Zarządu Fundacji trwa 5 lat kalendarzowych i kończy się po zatwierdzeniu sprawozdania Zarządu z działalności Fundacji za ostatni rok trwania kadencji.</li>
              <li>Zarząd Fundacji składa się z 2 do 5 osób powoływanych przez Radę Fundacji.</li>
              <li>Członkostwo Zarządu Fundacji ustaje z chwilą złożenia rezygnacji lub śmierci członka Zarządu Fundacji.</li>
              <li>Fundator może odwołać członka zarządu przed upływem końca kadencji tylko i wyłącznie <br />z powodu skazania prawomocnym wyrokiem za przestępstwo umyślne ścigane z oskarżenia publicznego lub za przestępstwo skarbowe;</li>
              </ol>
              <div className='divider padd-top-half text-center fs-125'>
                <p> &sect; 13</p>
              </div>
              <ol>
              <li>Zarząd Fundacji kieruje działalnością Fundacji i reprezentuje ją na zewnątrz. Do kompetencji Zarządu należą wszystkie sprawy nie zastrzeżone wprost do kompetencji innych organów Fundacji.</li>
              <li>Funkcję członka Zarządu można pełnić przez więcej niż jedną kadencję.</li>
              </ol>
              <div className='divider padd-top-half text-center fs-125'>
                <p> &sect; 14</p>
              </div>
              <ol>
              <li>Do zadań Zarządu należy w szczególności:
              <ol>
              <li type="a">uchwalanie rocznych planów działania Fundacji oraz planów finansowych;</li>
              <li type="a">uchwalanie regulaminów;</li>
              <li type="a">sprawowanie zarządu majątkiem Fundacji;</li>
              <li type="a">ustalanie wielkości zatrudnienia i wysokości środków na wynagrodzenia pracowników Fundacji;</li>
              <li type="a">podejmowanie decyzji we wszelkich sprawach nie przekazanych do kompetencji innych organów;</li>
              <li type="a">przyjmowanie darowizn, spadków i zapisów, subwencji i dotacji;</li>
              <li type="a">występowanie z wnioskiem i wyrażanie zgody w sprawie zmian statutu Fundacji, połączenia z inną Fundacją oraz likwidacja Fundacji.</li>
              </ol>
              </li>
              <li>Zarząd podejmuje decyzje na posiedzeniach w formie uchwał &ndash; zwykłą większością głosów jego członków obecnych na posiedzeniu Zarządu, o posiedzeniu muszą być powiadomieni wszyscy członkowie Zarządu.</li>
              <li>Zarząd może powoływać pełnomocników do kierowania wyodrębnioną sferą spraw należących do zadań Fundacji.</li>
              <li>Zarząd co roku, do dnia 31 marca, zobowiązany jest sporządzić sprawozdanie finansowe Fundacji za poprzedni rok.</li>
              </ol>
              
              
              <div className='divider padd-top-half text-center fs-125'>
                <p> &sect; 15</p>
              </div>
              <h4 className='text-center'>SPOSÓB REPREZENTACJI</h4>
              
              <ol>
              <li>Fundację reprezentuje Prezes i wiceprezes Zarządu łącznie.</li>
              </ol>
              
              <div className='divider padd-top-half text-center fs-125'>
                <p> &sect; 16</p>
              </div>
              <h4 className='text-center'>ZMIANA STATUTU</h4>
              
              <ol>
              <li>Zmian w statucie Fundacji dokonuje Zarząd</li>
              </ol>
              
              <div className='divider padd-top-half text-center fs-125'>
                <p> &sect; 17</p>
              </div>
              <h4 className='text-center'>POŁĄCZENIE Z INNĄ FUNDACJĄ</h4>
              <ol>
              <li>Fundacja może połączyć się z inną fundacją dla efektywnego realizowania swoich celów.</li>
              <li>Decyzja o połączeniu z inną Fundacją zapada w drodze jednomyślnej uchwały Zarządu.</li>
              </ol>
              
              <div className='divider padd-top-half text-center fs-125'>
                <p>	&sect; 18</p>
              </div>
              <p>W przypadku śmierci Fundatora wszelkie uprawnienia zastrzeżone dla niego jako Fundatora przez niniejszy statut przechodzą aktualnego Prezesa Zarządu.</p>
              <div className='divider padd-top-half text-center fs-125'>
                <p>	&sect; 19</p>
              </div>
              <h4 className='text-center'>LIKWIDACJA FUNDACJI</h4>
              <ol>
              <li>Fundacja ulega likwidacji w razie osiągnięcia celów, dla których została ustanowiona, lub <br />w razie wyczerpania środków finansowych i majątku.</li>
              <li>Likwidatorów Fundacji powołuje i odwołuje Zarząd Fundacji.</li>
              </ol>
              
              <div className='divider padd-top-half text-center fs-125'>
                <p>	&sect; 20</p>
              </div>
              <p>Decyzję o likwidacji podejmuje Zarząd Fundacji w drodze jednomyślnej uchwały.</p>
              
              
              <div className='divider padd-top-half text-center fs-125'>
                <p>	&sect; 21</p>
              </div>
              <p>Środki finansowe i majątek pozostały po likwidacji Fundacji mogą zostać przeznaczone mocą uchwały Zarządu Fundacji na rzecz działających w Rzeczpospolitej Polskiej organizacji społecznych o zbliżonych celach.</p>
              <div className='divider padd-top-half text-center fs-125'>
                <p>	&sect; 22</p>
              </div>
              <ol>
              <li>W przypadku śmierci Fundatora wszelkie uprawnienia zastrzeżone dla niego jako Fundatora przez niniejszy statut przechodzą na aktualnego Prezesa Zarządu;</li>
              <li>Niniejszy Statut został ustalony przez Fundatora i Zarząd Fundacji zgodnie z warunkami określonymi w akcie ustanowienia Fundacji oraz wynikającymi z przepisów ustawy, co zostaje potwierdzone złożeniem podpisu pod jego treścią</li>
              </ol>
            </div>
            </Intro>
          </Intro>
          
        </section>

        <Footer />
  
      </main>
      
    </>
  )
}

export default Page




